import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import Social from "../components/Social";

import play1 from "../images/play1.jpg";
import play3 from "../images/play3.jpg";
import play4 from "../images/play4.jpg";
import buddy from "../images/buddy.jpg";

import BrbDesktop from "../videos/brb_d.mp4";
import Buddy from "../videos/buddy.mp4";
import Iron from "../videos/irontickets.mp4";
import Happy from "../videos/happy.mp4";
import Dogfolio from "../videos/dogfolio.mp4";

import "../styles/play.scss";

import Video from "../components/Video";

const Play = ({ setCursorHovered, showIntro }) => {
  const [expanded, setExpanded] = useState(0);

  const transition = { ease: [0.16, 1, 0.3, 1] };

  const stagger = {
    initial: {
      opacity: 0,
      y: 100,
    },
    stagger: (custom) => {
      return {
        opacity: 1,
        y: 0,
        transition: {
          delay: custom,
          duration: 1,
          ease: [0.83, 0, 0.17, 1],
        },
      };
    },
  };

  showIntro(false);

  return (
    <motion.div className="main-container-play" exit={{ y: 30 }}>
      <div className="page-title">
        <div className="mask">
          <motion.p>FUN</motion.p>
        </div>
      </div>
      <Social setCursorHovered={setCursorHovered} />
      <div className="play-container">
        <motion.div className="grid">
          <motion.span
            custom={0}
            variants={stagger}
            initial="initial"
            animate="stagger"
            whileHover={{ scale: 1, y: -20 }}
            transition={{ duration: 0.2, ...transition }}
          >
            <motion.div
              className="tooltip"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 2 }}
            >
              A temporary portfolio page.
            </motion.div>

            <a
              href="http://eduardaliu.github.io/brb"
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={() => setCursorHovered(true)}
              onMouseLeave={() => setCursorHovered(false)}
            >
              <Video
                title={null}
                preview={BrbDesktop}
                badge="VanillaJS"
                addbadge={true}
                secondbadge="CSS"
                badgeBG="#000"
                message={null}
                muted={true}
                width={290}
              />{" "}
            </a>
          </motion.span>
          <motion.span
            custom={0.4}
            variants={stagger}
            initial="initial"
            animate="stagger"
            whileHover={{ scale: 1, y: -20 }}
            transition={{ duration: 0.5, ...transition }}
          >
            <motion.div
              className="tooltip"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 2 }}
            >
              A ticketing system for Ironhack TA's.
            </motion.div>
            <a
              href="http://github.com/IronTickets"
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={() => setCursorHovered(true)}
              onMouseLeave={() => setCursorHovered(false)}
            >
              <Video
                title={null}
                preview={Iron}
                badge="React"
                addbadge={true}
                secondbadge="MongoDB"
                addmorebadge={true}
                thirdbadge="Express"
                badgeBG="#000"
                message={null}
                muted={true}
                width={290}
                img={play4}
              />
            </a>
          </motion.span>
          <motion.span
            custom={0.2}
            variants={stagger}
            initial="initial"
            animate="stagger"
            whileHover={{ scale: 1, y: -20 }}
            transition={{ duration: 0.5, ...transition }}
          >
            <motion.div
              className="tooltip"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 2 }}
            >
              A donation site for my sick pup.
            </motion.div>{" "}
            <a
              href="https://github.com/eduardaliu/savebuddy"
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={() => setCursorHovered(true)}
              onMouseLeave={() => setCursorHovered(false)}
            >
              <Video
                title={null}
                preview={Buddy}
                badge="VanillaJS"
                addbadge={true}
                secondbadge="CSS"
                badgeBG="#000"
                message={null}
                muted={true}
                width={290}
                img={buddy}
              />
            </a>
          </motion.span>
          <motion.span
            custom={0.5}
            variants={stagger}
            initial="initial"
            animate="stagger"
            whileHover={{ scale: 1, y: -20 }}
            transition={{ duration: 0.5, ...transition }}
          >
            {" "}
            <motion.div
              className="tooltip"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 2 }}
            >
              A silly browser game.
            </motion.div>{" "}
            <a
              href="http://eduardaliu.github.io/happymeter/"
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={() => setCursorHovered(true)}
              onMouseLeave={() => setCursorHovered(false)}
            >
              <Video
                title={null}
                preview={Happy}
                badge="VanillaJS"
                addbadge={true}
                secondbadge="CSS"
                badgeBG="#000"
                message={null}
                muted={true}
                width={290}
                img={play3}
              />
            </a>
          </motion.span>
          <motion.span
            variants={stagger}
            initial="initial"
            animate="stagger"
            whileHover={{ scale: 1, y: -20 }}
            transition={{ duration: 0.5, ...transition }}
          >
            {" "}
            <motion.div
              className="tooltip"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 2 }}
            >
              An old art direction portfolio of mine.
            </motion.div>{" "}
            <a
              href="https://olddogportifs.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={() => setCursorHovered(true)}
              onMouseLeave={() => setCursorHovered(false)}
            >
              <Video
                title={null}
                preview={Dogfolio}
                badge="No-code"
                badgeBG="#000"
                message={null}
                muted={true}
                width={290}
                img={play1}
              />
            </a>
          </motion.span>
        </motion.div>
      </div>

      <div className="play-container-mobile">
        <PlayMobile
          i="1"
          expanded={expanded}
          setExpanded={setExpanded}
          title="BeRightBack"
          videosrc={BrbDesktop}
          description="A temporary portfolio page made with JS and CSS."
        />
        <PlayMobile
          i="2"
          expanded={expanded}
          setExpanded={setExpanded}
          title="Save Buddy"
          videosrc={Buddy}
          description="A donation website for my sick puppy. Made with JS and CSS."
        />
        <PlayMobile
          i="3"
          expanded={expanded}
          setExpanded={setExpanded}
          title="Happymeter"
          videosrc={Happy}
          description="A silly browser game, made with JS and CSS."
        />
        <PlayMobile
          i="4"
          expanded={expanded}
          setExpanded={setExpanded}
          title="Dogfolio"
          videosrc={Dogfolio}
          description="An old portfolio of mine, made with no-code builders (long before I learned how to code)."
        />
        <PlayMobile
          i="5"
          expanded={expanded}
          setExpanded={setExpanded}
          title="IronTickets"
          videosrc={Iron}
          description="A ticketing system for Ironhack TA's, made with React, Express and MongoDB."
        />
      </div>
    </motion.div>
  );
};

const Plus = ({ i, expanded }) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="13.62" x2="27" y2="13.62" stroke="black" strokeWidth="5" />
      <motion.line
        x1="13.62"
        y1="27"
        x2="13.62"
        y2="6.55671e-08"
        stroke="black"
        strokeWidth="5"
        initial={{ opacity: 1 }}
        animate={{
          opacity: expanded === i ? 1 : 1,
          rotate: expanded === i ? 90 : 0,
        }}
        // animate= {{rotate: expanded ? 180 : 180}}
      />
    </svg>
  );
};

const PlayMobile = ({
  i,
  expanded,
  setExpanded,
  videosrc,
  description,
  title,
}) => {
  return (
    <div className="project">
      <span>
        <motion.header
          initial={false}
          // animate={{ backgroundColor: i === expanded ? "#FF0088" : "#0055FF" }}
          onClick={() => setExpanded(i === expanded ? false : i)}
        >
          <div className="line one"></div>
          <span>
            <motion.h1>{title}</motion.h1>
            <Plus i={i} expanded={expanded} className="desktop" />
          </span>
        </motion.header>
      </span>
      <AnimatePresence initial={false}>
        {i === expanded && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
            className="accordion-content"
          >
            <video width="320" height="240" autoPlay muted>
              <source src={videosrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <p>{description}</p>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Play;
