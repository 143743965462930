import React from "react";
import { Link, useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

import "../styles/main.scss";

import { ReactComponent as Home } from "../images/home.svg";
import { ReactComponent as Close } from "../images/close.svg";
import { ReactComponent as Next } from "../images/arrownext.svg";
import { ReactComponent as Back } from "../images/arrowback.svg";

const Navigation = ({
  setCursorHovered,
  showPanels,
  showGreenPanels,
  showRedPanels,
  showYellowPanels,
  showBluePanels,
}) => {
  const location = useLocation();

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  let project = null;
  if (location.pathname === "/project/switch") {
    project = (
      <ProjectNav
        setCursorHovered={setCursorHovered}
        showGreenPanels={showGreenPanels}
        showPanels={showPanels}
        scrollToTop={scrollToTop}
        previous="/project/medmen"
        next="/project/playpaw"
      />
    );
  } else if (location.pathname === "/project/playpaw") {
    project = (
      <ProjectNav
        setCursorHovered={setCursorHovered}
        showGreenPanels={showGreenPanels}
        showPanels={showPanels}
        scrollToTop={scrollToTop}
        previous="/project/switch"
        next="/project/medmen"
      />
    );
  } else if (location.pathname === "/project/medmen") {
    project = (
      <ProjectNav
        setCursorHovered={setCursorHovered}
        showGreenPanels={showGreenPanels}
        showPanels={showPanels}
        scrollToTop={scrollToTop}
        previous="/project/playpaw"
        next="/project/switch"
      />
    );
  }

  return (
    <div className="nav-container">
      {project !== null ? (
        project
      ) : (
        <MainNav
          setCursorHovered={setCursorHovered}
          showPanels={showPanels}
          showGreenPanels={showGreenPanels}
          showRedPanels={showRedPanels}
          showYellowPanels={showYellowPanels}
          showBluePanels={showBluePanels}
        />
      )}
    </div>
  );
};

const MainNav = ({
  setCursorHovered,
  showGreenPanels,
  showRedPanels,
  showYellowPanels,
  showBluePanels,
}) => {
  const location = useLocation();

  return (
    <>
      <Link
        to="/"
        className="home"
        onClick={() => {
          showRedPanels(true);
        }}
      >
        <Home />
        {location.pathname === "/" && <div className="circlehome"></div>}
      </Link>
      <ul>
        <li
          onMouseEnter={() => setCursorHovered(true)}
          onMouseLeave={() => setCursorHovered(false)}
        >
          <Link
            to="/work"
            onClick={() => {
              showGreenPanels(true);
            }}
          >
            WORK
          </Link>
          {location.pathname === "/work" && <div className="circlework"></div>}
        </li>
        <li
          onMouseEnter={() => setCursorHovered(true)}
          onMouseLeave={() => setCursorHovered(false)}
        >
          <Link
            to="/fun"
            onClick={() => {
              showYellowPanels(true);
            }}
          >
            FUN
          </Link>
          {location.pathname === "/fun" && <div className="circleplay"></div>}
        </li>
        <li
          onMouseEnter={() => setCursorHovered(true)}
          onMouseLeave={() => setCursorHovered(false)}
        >
          <Link
            to="/about"
            onClick={() => {
              showBluePanels(true);
            }}
          >
            ABOUT
          </Link>
          {location.pathname === "/about" && (
            <div className="circleabout"></div>
          )}
        </li>
      </ul>
    </>
  );
};

const ProjectNav = ({
  setCursorHovered,
  showGreenPanels,
  showPanels,
  scrollToTop,
  next,
  previous,
}) => {
  return (
    <>
      <Link
        to="/work"
        className="close"
        onMouseEnter={() => setCursorHovered(true)}
        onMouseLeave={() => setCursorHovered(false)}
        onClick={() => (scrollToTop(), showGreenPanels(true))}
      >
        <Close /> <span>CLOSE PROJECT</span>
      </Link>
      <ul className="backnext">
        <li
          onMouseEnter={() => setCursorHovered(true)}
          onMouseLeave={() => setCursorHovered(false)}
        >
          <Link
            to={previous}
            onMouseEnter={() => setCursorHovered(true)}
            onMouseLeave={() => setCursorHovered(false)}
            onClick={() => (scrollToTop(), showPanels(true))}
          >
            <Back /> PREVIOUS
          </Link>
        </li>
        <li
          onMouseEnter={() => setCursorHovered(true)}
          onMouseLeave={() => setCursorHovered(false)}
        >
          <Link
            to={next}
            onMouseEnter={() => setCursorHovered(true)}
            onMouseLeave={() => setCursorHovered(false)}
            onClick={() => (scrollToTop(), showPanels(true))}
          >
            NEXT <Next />
          </Link>
        </li>
      </ul>
    </>
  );
};

export default Navigation;
