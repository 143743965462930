import React from "react";
import { motion } from "framer-motion";

import "../styles/video.scss";

const Video = ({
  title,
  preview,
  badge,
  addbadge,
  addmorebadge,
  secondbadge,
  thirdbadge,
  badgeBG,
  message,
  muted,
  width,
  classname,
  img,
  layoutId,
}) => {
  const onHover = (e) => {
    e.target.play();
    e.target.nextElementSibling.style.opacity = 0;
  };
  const offHover = (e) => {
    e.target.pause();
    e.target.nextElementSibling.style.opacity = 1;
  };
  return (
    <motion.div
      style={{ width: width }}
      className="videoThumbnail"
      layoutId={layoutId}
    >
      <div style={{ width: width }} className="video">
        <motion.video
          onMouseEnter={(e) => onHover(e)}
          onMouseOut={(e) => offHover(e)}
          className="preview"
          width={width}
          src={preview}
          loop
          muted={muted}
          poster={img}
        />

        <div className="badgecontainer">
          {" "}
          <div className="badge">
            {badge}
          </div>
          {addbadge && (
            <div className="badge">
              {secondbadge}
            </div>
          )}
          {addmorebadge && (
            <div className="badge">
              {thirdbadge}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default Video;
