import React, { useState } from "react";

import { motion } from "framer-motion";

import Social from "../components/Social";
import Hamburg from "../videos/hamburg_small.mp4";
import Bsb from "../videos/bsb_small.mp4";

// import churrasco from "../videos/churrasco.mp4";
// import currywurst from "../videos/curryw.mp4";

const Home = ({ setCursorHovered }) => {
  const [germany, showGermany] = useState(false);
  const [brazil, showBrazil] = useState(false);

  const transition = { ease: [0.16, 1, 0.3, 1] };

  const stagger = {
    initial: {
      opacity: 0,
      y: 100,
    },
    stagger: (custom) => {
      return {
        opacity: 1,
        y: 0,
        transition: {
          delay: custom,
          duration: 1,
          ease: [0.83, 0, 0.17, 1],
        },
      };
    },
  };

  return (
    <>
      {germany && (
        <motion.video
          initial={{ scale: 1.2, opacity: 0.0, skewY: 10, y: 0 }}
          animate={{ scale: 1.2, opacity: 1, skewY: 0, y: 0 }}
          transition={{ duration: 1, ease: [0.6, 0.01, -0.05, 0.9] }}
          className="video-bg"
          autoPlay
          loop
          muted
        >
          <source src={Hamburg} type="video/mp4" />
        </motion.video>
      )}
      {brazil && (
        <motion.video
          initial={{ scale: 1.2, opacity: 0.0, skewY: 10, y: 0 }}
          animate={{ scale: 1.2, opacity: 1, skewY: 0, y: 0 }}
          transition={{ duration: 1, ease: [0.6, 0.01, -0.05, 0.9] }}
          className="video-bg"
          autoPlay
          loop
          muted
        >
          <source src={Bsb} type="video/mp4" />
        </motion.video>
      )}
      <motion.div className="main-container-home" exit={{ y: 10 }}>
        <motion.div className="page-title">
          <div className="mask">
            <motion.p>HOME</motion.p>
          </div>
        </motion.div>
        <Social setCursorHovered={setCursorHovered} />
        <div className="home-content">
          <div className="home-flex">
            <div className="text">
              <motion.h1
                custom={0}
                variants={stagger}
                initial="initial"
                animate="stagger"
                transition={{ duration: 0.2, ...transition }}
              >
                <span>I’m a</span> former art director turned{" "}
                <span>product designer. </span>
              </motion.h1>
              <motion.p
                custom={0.2}
                variants={stagger}
                initial="initial"
                animate="stagger"
                transition={{ duration: 0.2, ...transition }}
              >
                I'm originally from{" "}
                <span
                  onMouseEnter={() => showBrazil(true)}
                  onMouseLeave={() => showBrazil(false)}
                >
                  Brazil
                </span>{" "}
                but am currently based in{" "}
                <span
                  onMouseEnter={() => showGermany(true)}
                  onMouseLeave={() => showGermany(false)}
                >
                  Germany
                </span>
                .
              </motion.p>
              <motion.p
                              custom={0.2}

                variants={stagger}
                initial="initial"
                animate="stagger"
                transition={{ duration: 0.2, ...transition }}
              >
                I enjoy designing delightful experiences that live on the web.
              </motion.p>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Home;
