import React, { useState } from "react";

import { motion, AnimatePresence } from "framer-motion";

import Social from "../components/Social";
import foodshower from "../components/Emoji";

import CV from "../etc/012021_cv_eduardaliu.pdf";

import photo from "../images/fotita2.png";
import photoW from "../images/fotita2.webp";

import photoBud from "../images/fotita2buddy.png";
import photoBudW from "../images/fotita2buddy.webp";

import "../styles/about.scss";

import { ReactComponent as Circle } from "../images/circle.svg";

const About = ({ setCursorHovered, showIntro }) => {
  const [emojis, setEmojis] = useState(false);
  const [buddy, setBuddy] = useState(false);

  const transition = { ease: [0.16, 1, 0.3, 1] };

  const stagger = {
    initial: {
      opacity: 0,
      y: 100,
    },
    stagger: (custom) => {
      return {
        opacity: 1,
        y: 0,
        transition: {
          delay: custom,
          duration: 1,
          ease: [0.83, 0, 0.17, 1],
        },
      };
    },
  };

  showIntro(false);

  if (emojis === true) {
    foodshower();
  }

  return (
    <>
      <motion.div className="main-container-about" exit={{ y: 30 }}>
        <AnimatePresence>
          {" "}
          {emojis && (
            <motion.div
              className="foodshower"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.3,
                ...transition,
              }}
            ></motion.div>
          )}{" "}
        </AnimatePresence>

        <div className="page-title above">
          <div className="mask">
            <motion.p>ABOUT</motion.p>
          </div>
        </div>
        <Social setCursorHovered={setCursorHovered} />
        <div className="about-container">
          <div className="top">
            <div className="right">
              <div className="title-test">
                {/* <div className="mask-title">
                  <motion.ul
                    initial={{ y: -2 }}
                    animate={{ y: [-2, -54, -108, -158] }}
                    transition={{
                      repeat: Infinity,
                      repeatDelay: 1,
                      delay: 1.5,
                      duration: 4,
                      ...transition,
                    }}
                  >
                    <li> Hey</li> <li>Hello </li> <li>Ahoy</li> <li>Hi</li>
                  </motion.ul>
                </div> */}
                <motion.h1
                  custom={0}
                  variants={stagger}
                  initial="initial"
                  animate="stagger"
                  transition={{ duration: 0.2, ...transition }}
                >
                  Welcome to my crib
                </motion.h1>
              </div>

              <motion.div
                className="text"
                custom={0.2}
                variants={stagger}
                initial="initial"
                animate="stagger"
                transition={{ duration: 0.2, ...transition }}
              >
                {" "}
                <p>
                  Born in the land of Carnival, raised by Asian mom standards. A
                  few years ago I've exchanged my flip flops for rain boots and
                  am now living in Hamburg.
                </p>
                <p>
                  I bring 5 years of experience with art direction and creative
                  conception to design experiences that are not only useful but
                  also lovely. I believe beautiful things function better, so
                  I’m all about that pixel perfection. Coding is a newfound
                  passion, so you can often find me breaking my head over a
                  "Failed to compile" message.
                </p>
                <p>
                  Unrelated to work, I’m obsessed with cooking{" "}
                  <span
                    onMouseEnter={() => setEmojis(true)}
                    onMouseLeave={() => setEmojis(false)}
                  >
                    excessive amounts of food
                  </span>{" "}
                  and being the best possible human to{" "}
                  <span
                    onMouseEnter={() => setBuddy(true)}
                    onMouseLeave={() => setBuddy(false)}
                  >
                    my dog Buddy.
                  </span>
                </p>
                <p>
                  <a href={CV} target="_blank" rel="noreferrer">
                    {" "}
                    <button
                      onMouseEnter={() => setCursorHovered(true)}
                      onMouseLeave={() => setCursorHovered(false)}
                    >
                      Download my CV
                    </button>
                  </a>
                </p>
              </motion.div>
            </div>
            <div className="left">
              <div className="mask">
                <AnimatePresence>
                  {buddy && (
                    <div className="buddy">
                      <picture>
                        <source srcSet={photoBudW} type="image/webp" />
                        <source srcSet={photoBud} type="image/jpeg" />
                        <motion.img
                          src={photoBud}
                          alt="A pic of me"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{
                            delay: 0.2,
                            duration: 0.2,
                            ease: [0.04, 0.62, 0.23, 0.98],
                          }}
                        />
                      </picture>
                    </div>
                  )}
                </AnimatePresence>
                <picture>
                  <source srcSet={photoW} type="image/webp" />
                  <source srcSet={photo} type="image/jpeg" />
                  <motion.img
                    src={photo}
                    alt="A pic of me"
                    initial={{ scale: 1.5 }}
                    animate={{ scale: 1 }}
                    transition={{
                      duration: 0.8,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  />
                </picture>
              </div>
              <Circle />
            </div>
          </div>
          <motion.div
            custom={0.4}
            variants={stagger}
            initial="initial"
            animate="stagger"
            transition={{ duration: 0.2, ...transition }}
            className="bottom"
          >
            {" "}
            <div className="info">
              <h5>Creative Stack</h5>
              <ul>
                <li>Design Thinking</li>
                <li>Interaction Design</li>
                <li>Prototyping</li>
                <li>Interface Design</li>
              </ul>
            </div>
            <div className="info second">
              <ul>
                <li>Visual Design</li>
                <li>Motion Design</li>
                <li>Creative Conception</li>
                <li>Art Direction</li>
              </ul>
            </div>
            <div className="info">
              <h5>Tech Stack</h5>
              <ul>
                <li>HTML5</li>
                <li>CSS3/SASS</li>
                <li>JavaScript ES6</li>
                <li>React</li>
              </ul>
            </div>
            <div className="info">
              <h5>Life Stack</h5>
              <ul>
                <li>Char-siu bao making</li>
                <li>Socks mismatching</li>
                <li>Dog belly rubbing</li>
                <li>Redditing at 4AM</li>
              </ul>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};

export default About;
