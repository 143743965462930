export default function foodshower() {
  let container = document.querySelector(".foodshower");
  let emoji = ['🥟', '🌮', '🧆', '🍔', '🥗', '🍜', '🍛', '🍕', '🍗', '🥞', '🥯'];
  let circles = [];

for (let i = 0; i < 5
     ; i++) {
  addCircle(i * 800, [0, 2400], emoji[Math.floor(Math.random() * emoji.length)]);
}
  function addCircle(delay, range, color) {
    setTimeout(function () {
      let c = new Circle(
        range[0] + Math.random() * range[1],
        80 + Math.random() * 40,
        color,
        {
          x: -0.15 + Math.random() * 0.3,
          y: 1 + Math.random() * 1,
        },
        range
      );
      circles.push(c);
    }, delay);
  }

  function Circle(x, y, c, v, range) {
    let _this = this;
    this.x = x;
    this.y = y;
    this.color = c;
    this.v = v;
    this.range = range;
    this.element = document.createElement("span");
    this.element.style.opacity = 0;
    this.element.style.position = "absolute";
    this.element.style.fontSize = "26px";
    this.element.style.color =
      "hsl(" + ((Math.random() * 360) | 0) + ",80%,50%)";
    this.element.innerHTML = c;
    if (container !== null) {
      container.appendChild(this.element);
    }

    this.update = function () {
      if (_this.y > 800) {
        _this.y = 80 + Math.random() * 4;
        _this.x = _this.range[0] + Math.random() * _this.range[1];
      }
      _this.y += _this.v.y;
      _this.x += _this.v.x;
      this.element.style.opacity = 1;
      this.element.style.transform =
        "translate3d(" + _this.x + "px, " + _this.y + "px, 0px)";
      this.element.style.webkitTransform =
        "translate3d(" + _this.x + "px, " + _this.y + "px, 0px)";
      this.element.style.mozTransform =
        "translate3d(" + _this.x + "px, " + _this.y + "px, 0px)";
    };
  }

  function animate() {
    for (let i in circles) {
      circles[i].update();
    }
    requestAnimationFrame(animate);
  }

  animate();
}
