import React from "react";
import { motion } from "framer-motion";

const transition = { duration: 0.8, ease: [0.6, -0.05, 0.01, 0.9] };

const Panels = ({ showPanels }) => {
  return (
    <motion.div
      initial={{ height: 0, top: 0 }}
      animate={{ height: 3000, top: window.innerHeight }}
      exit={{ height: 0, bottom: 0 }}
      transition={{ ...transition, duration:1}}
      className="panel-background"
      onAnimationComplete={() => {
        showPanels(false);
      }}
    ></motion.div>
  );
};

const GreenPanels = ({ showGreenPanels }) => {
  return (
    <motion.div
      initial={{ height: 0, top: 0 }}
      animate={{ height: 7000, top: window.innerHeight }}
      exit={{ height: 0, bottom: 0 }}
      transition={{ ...transition, duration:1.5}}
      className="panel-background green"
      onAnimationComplete={() => {
        showGreenPanels(false);
      }}
    ></motion.div>
  );
};

const YellowPanels = ({ showYellowPanels }) => {
  return (
    <motion.div
      initial={{ height: 0, top: 0 }}
      animate={{ height: 7000, top: window.innerHeight }}
      exit={{ height: 0, bottom: 0 }}
      transition={{ ...transition, duration:1.5}}
      className="panel-background yellow"
      onAnimationComplete={() => {
        showYellowPanels(false);
      }}
    ></motion.div>
  );
};

const BluePanels = ({ showBluePanels }) => {
  return (
    <motion.div
      initial={{ height: 0, top: 0 }}
      animate={{ height: 7000, top: window.innerHeight }}
      exit={{ height: 0, bottom: 0 }}
      transition={{ ...transition, duration:1.5}}
      className="panel-background blue"
      onAnimationComplete={() => {
        showBluePanels(false);
      }}
    ></motion.div>
  );
};

const RedPanels = ({ showRedPanels }) => {
  return (
    <motion.div
      initial={{ height: 0, top: 0}}
      animate={{ height: 7000, top: window.innerHeight }}
      exit={{ height: 0, bottom: 0 }}
      transition={{ ...transition, duration:1.5}}
      className="panel-background red"
      onAnimationComplete={() => {
        showRedPanels(false);
      }}
    ></motion.div>
  );
};

export {Panels, GreenPanels, YellowPanels, RedPanels, BluePanels};
