import React from "react";
import { motion } from "framer-motion";

import "../styles/intro.scss";

const transition = {
  default: { duration: 1, ease: [0.6, 0.01, -0.05, 0.9] },
  delayed: {
    delay: 1.3,
    duration: 1,
    ease: [0.6, 0.01, -0.05, 0.9],
  },
};

const panel = {
  initial: { width: 0, y: 0 },
  animate: {
    width: "100%",
    y: "-100vh",
  },
};

const introContainer = {
  initial: {
    opacity: 1,
  },
  animate: { opacity: 1, y: "-200vh" },
};

const smile = {
  initial: { y: "0px", opacity: 0 },
  animate: { y: "-30px", opacity: 1, rotate: 360 },
};

const heart = {
  hidden: { opacity: 0, scale: 0.5 },
  show: { y: "-30px", opacity: 1, scale: 1 },
};

const Intro = ({ showIntro }) => {

  return (
    <>
      <motion.div
        className="intro-test"
        variants={introContainer}
        initial="initial"
        animate="animate"
        transition={{ delay: 3.9, ...transition.default }}
        onAnimationComplete={() => {
          showIntro(false);
        }}
      >
        <motion.div className="intro-text">
          <motion.div
            variants={heart}
            initial="hidden"
            animate="show"
            transition={{
              delay: 1.5,
              duration: 2,
              ease: [0.6, 0.01, -0.05, 0.9],
            }}
          >
            <svg
              width="27"
              height="24"
              viewBox="0 0 27 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.3793 1.64103C21.4895 -0.860706 17.1917 -0.410714 14.5392 2.36959L13.5004 3.45707L12.4615 2.36959C9.81433 -0.410714 5.51128 -0.860706 2.6215 1.64103C-0.69016 4.51241 -0.86418 9.66589 2.09943 12.7783L12.3033 23.4817C12.9625 24.1728 14.033 24.1728 14.6922 23.4817L24.8961 12.7783C27.865 9.66589 27.6909 4.51241 24.3793 1.64103V1.64103Z"
                fill="#F35F20"
              />
            </svg>
          </motion.div>

          <motion.div
            className="svg"
            variants={smile}
            initial="initial"
            animate="animate"
            transition={{ delay: 1, ...transition.default }}
          >
            <svg
              width="44"
              height="45"
              viewBox="0 0 44 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.3077 21.875C16.5822 21.875 17.6154 20.8418 17.6154 19.5673C17.6154 18.2928 16.5822 17.2596 15.3077 17.2596C14.0332 17.2596 13 18.2928 13 19.5673C13 20.8418 14.0332 21.875 15.3077 21.875Z"
                fill="white"
              />
              <path
                d="M29.1538 21.875C30.4283 21.875 31.4615 20.8418 31.4615 19.5673C31.4615 18.2928 30.4283 17.2596 29.1538 17.2596C27.8793 17.2596 26.8461 18.2928 26.8461 19.5673C26.8461 20.8418 27.8793 21.875 29.1538 21.875Z"
                fill="white"
              />
              <path
                d="M22 42.875C33.0457 42.875 42 33.9207 42 22.875C42 11.8293 33.0457 2.875 22 2.875C10.9543 2.875 2 11.8293 2 22.875C2 33.9207 10.9543 42.875 22 42.875Z"
                stroke="white"
                strokeWidth="4"
              />
              <path
                d="M30.6 26.2H26.2C26.2 27.367 25.7364 28.4861 24.9113 29.3113C24.0861 30.1364 22.967 30.6 21.8 30.6C20.633 30.6 19.5139 30.1364 18.6887 29.3113C17.8636 28.4861 17.4 27.367 17.4 26.2H13C13 28.5339 13.9271 30.7722 15.5775 32.4226C17.2278 34.0729 19.4661 35 21.8 35C24.1339 35 26.3722 34.0729 28.0225 32.4226C29.6729 30.7722 30.6 28.5339 30.6 26.2Z"
                fill="#FEFEFE"
              />
            </svg>
          </motion.div>
          <div>
            <div className="mask">
              <motion.h2
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                transition={{
                  duration: 1,
                  ease: [0.6, 0.01, -0.05, 0.9],
                }}
              >
                Hey! I'm Eduarda Liu.
              </motion.h2>
            </div>
            <motion.div
              className="line"
              initial={{ opacity: 0, width: 0 }}
              animate={{ opacity: 1, width: "100%" }}
              transition={{ duration: 0.8, ease: [0.6, 0.01, -0.05, 0.9] }}
            ></motion.div>
          </div>
          <div>
            <div className="mask">
              <motion.h2
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                transition={{
                  delay: 0.6,
                  duration: 1,
                  ease: [0.6, 0.01, -0.05, 0.9],
                }}
              >
                I design and code things.
              </motion.h2>
            </div>
            <motion.div
              className="line"
              initial={{ opacity: 0, width: 0 }}
              animate={{ opacity: 1, width: "100%" }}
              transition={{
                delay: 0.5,
                duration: 1,
                ease: [0.6, 0.01, -0.05, 0.9],
              }}
            ></motion.div>
          </div>
        </motion.div>

        <motion.div
          variants={panel}
          animate="animate"
          transition={{ delay: 3.9, ...transition.default }}
          className="container-black"
        ></motion.div>

        <motion.div
          variants={panel}
          animate="animate"
          transition={{ delay: 3.5, ...transition.default }}
          className="container-red"
        >
          <motion.div
            variants={panel}
            animate="animate"
            transition={{ delay: 3.4, ...transition.default }}
            className="container-black"
          ></motion.div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Intro;
