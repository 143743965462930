import React from "react";

import { motion } from "framer-motion";

import { ReactComponent as Linkedin } from "../images/linkedin.svg";
import { ReactComponent as Mail } from "../images/mail.svg";
import { ReactComponent as Github } from "../images/gihub.svg";

import "../styles/main.scss";

const Social = ({ setCursorHovered }) => {
  const settings = {
    scale: 1.1,
    y: "-5px",
    transition: { duration: 0.2 },
  };

  return (
    <div className="social-links">
      <motion.span whileHover={settings}>
        <a href="mailto:eduardaliu@gmail.com">
          <Mail
            style={{ width: "24px" }}
            onMouseEnter={() => setCursorHovered(true)}
            onMouseLeave={() => setCursorHovered(false)}
          />
        </a>
      </motion.span>
      <motion.span whileHover={settings}>
        <a
          href="https://www.linkedin.com/in/eduardaliu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Linkedin
            style={{ width: "21px" }}
            onMouseEnter={() => setCursorHovered(true)}
            onMouseLeave={() => setCursorHovered(false)}
          />
        </a>
      </motion.span>
      <motion.span whileHover={settings}>
        <a href="https://github.com/eduardaliu" target="_blank" rel="noopener noreferrer">
          <Github
            style={{ width: "22px" }}
            onMouseEnter={() => setCursorHovered(true)}
            onMouseLeave={() => setCursorHovered(false)}
          />
        </a>
      </motion.span>
      <motion.span
        className="line"
        // initial={{ height: 0 }}
        // animate={{ height: "90px" }}
        // transition={{
        //   // delay: 0.6,
        //   duration: 1,
        //   ease: [0.6, 0.01, -0.05, 0.9],
        // }}
      ></motion.span>
    </div>
  );
};

export default Social;
