import React, { useState, lazy, Suspense } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import useMousePosition from "./hooks/useMousePosition";

import {
  Panels,
  GreenPanels,
  YellowPanels,
  RedPanels,
  BluePanels,
} from "./components/Panels";

//Styles
import "./styles/normalize.scss";
import "./styles/main.scss";

import Home from "./pages/home";
import Work from "./pages/work";
import Play from "./pages/play";
import About from "./pages/about";

import Navigation from "./components/Nav";
import Intro from "./components/Intro";

// import ProjectOne from "./components/projects/ProjectOne.js";
// import ProjectTwo from "./components/projects/ProjectTwo.js";
// import ProjectThree from "./components/projects/ProjectThree.js";

const projectOnePromise = import("./components/projects/ProjectOne.js");
const projectTwoPromise = import("./components/projects/ProjectTwo.js");
const projectThreePromise = import("./components/projects/ProjectThree.js");

const ProjectOne = lazy(() => import("./components/projects/ProjectOne.js"));
const ProjectTwo = lazy(() => import("./components/projects/ProjectTwo.js"));
const ProjectThree = lazy(() =>
  import("./components/projects/ProjectThree.js")
);

function App() {
  const [intro, showIntro] = useState(true);
  const [panels, showPanels] = useState(false);
  const [greenPanels, showGreenPanels] = useState(false);
  const [yellowPanels, showYellowPanels] = useState(false);
  const [bluePanels, showBluePanels] = useState(false);
  const [redPanels, showRedPanels] = useState(false);
  const [cursorHovered, setCursorHovered] = useState(false);
  const { x, y } = useMousePosition();

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="loading">
            {/* <video height="300" autoPlay muted loop> */}
            <h1>LOADING</h1>
            {/* <source src={Loading} type="video/mp4" /> */}
            {/* Your browser does not support the video tag.
            </video> */}
          </div>
        }
      >
        <Navigation
          setCursorHovered={setCursorHovered}
          showPanels={showPanels}
          showGreenPanels={showGreenPanels}
          showYellowPanels={showYellowPanels}
          showBluePanels={showBluePanels}
          showRedPanels={showRedPanels}
        />

        {intro ? <Intro showIntro={showIntro} /> : null}

        <Route
          render={({ location }) => (
            <AnimatePresence exitBeforeEnter>
              <Switch location={location} key={location.pathname}>
                <Route
                  exact
                  path="/"
                  render={() => (
                    <Home
                      setCursorHovered={setCursorHovered}
                      showPanels={showPanels}
                      x={x}
                      y={y}
                    />
                  )}
                />
                <Route
                  exact
                  path="/work"
                  render={() => (
                    <Work
                      cursorHovered={cursorHovered}
                      setCursorHovered={setCursorHovered}
                      showPanels={showPanels}
                      x={x}
                      y={y}
                      showIntro={showIntro}
                    />
                  )}
                />
                <Route
                  exact
                  path="/fun"
                  render={() => (
                    <Play
                      setCursorHovered={setCursorHovered}
                      showIntro={showIntro}
                    />
                  )}
                />
                <Route
                  exact
                  path="/about"
                  render={() => (
                    <About
                      setCursorHovered={setCursorHovered}
                      showIntro={showIntro}
                    />
                  )}
                />

                <Route
                  exact
                  path="/project/switch"
                  render={() => (
                    <ProjectOne
                      cursorHovered={cursorHovered}
                      setCursorHovered={setCursorHovered}
                      showPanels={showPanels}
                      x={x}
                      y={y}
                      showIntro={showIntro}
                    />
                  )}
                />

                <Route
                  exact
                  path="/project/playpaw"
                  render={() => (
                    <ProjectTwo
                      cursorHovered={cursorHovered}
                      setCursorHovered={setCursorHovered}
                      showPanels={showPanels}
                      x={x}
                      y={y}
                      showIntro={showIntro}
                    />
                  )}
                />

                <Route
                  exact
                  path="/project/medmen"
                  render={() => (
                    <ProjectThree
                      cursorHovered={cursorHovered}
                      setCursorHovered={setCursorHovered}
                      showPanels={showPanels}
                      x={x}
                      y={y}
                      showIntro={showIntro}
                    />
                  )}
                />
              </Switch>
            </AnimatePresence>
          )}
        />
        <Cursor
          x={x}
          y={y}
          cursorHovered={cursorHovered}
          className="hide-mobile"
        />
        {panels && <Panels showPanels={showPanels} />}
        {greenPanels && <GreenPanels showGreenPanels={showGreenPanels} />}
        {yellowPanels && <YellowPanels showYellowPanels={showYellowPanels} />}
        {bluePanels && <BluePanels showBluePanels={showBluePanels} />}
        {redPanels && <RedPanels showRedPanels={showRedPanels} />}
      </Suspense>
    </BrowserRouter>
  );
}

const Cursor = ({ x, y, cursorHovered }) => {
  return (
    <motion.div
      animate={{
        x: x - 20,
        y: y - 20,
        scale: cursorHovered ? 1 : 0.4,
        opacity: cursorHovered ? 1 : 1,
      }}
      transition={{ duration: 0.4, ease: [0.16, 1, 0.3, 1] }}
      className="cursor"
    ></motion.div>
  );
};

export default App;
