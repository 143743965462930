import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";


import useHover from "../hooks/useHover";

import { motion } from "framer-motion";

import Social from "../components/Social";

import Switch from "../videos/switch/switch_intro.mp4";
import PlayPaw from "../videos/playpaw/playpaw_intro.mp4";
import MedMen from "../videos/medmen/medmen_intro.mp4";

const Work = ({ setCursorHovered, cursorHovered, showPanels, x, y, showIntro }) => {
  let list = useRef();

  const [listPosition, setListPosition] = useState({ top: 0, left: 0 });

  const [hoverProjectOne, projectOne] = useHover();
  const [hoverProjectTwo, projectTwo] = useHover();
  const [hoverProjectThree, projectThree] = useHover();

  useEffect(() => {
    setListPosition({
      top: list.current.getBoundingClientRect().top,
      left: list.current.getBoundingClientRect().left,
    });
  }, [cursorHovered]);

  showIntro(false);

  const transition = { ease: [0, 0.55, 0.45, 1] };

  const stagger = {
    initial: {
      opacity: 0,
      y: 50,
    },
    stagger: (custom) => {
      return {
        opacity: 1,
        y: 0,
        transition: {
          delay: custom,
          duration: 0.6,
          // ease: [0.22, 1, 0.36, 1],
        },
      };
    },
  };

  return (
    <motion.div className="main-container-work" exit={{ y: 30 }}>
      <motion.div className="page-title">
        <div className="mask">
          <motion.p>WORK</motion.p>
        </div>
      </motion.div>
      <Social setCursorHovered={setCursorHovered} />
      <motion.div
        className="project-titles" // key="test"
      >
        <motion.div
          custom={0}
          variants={stagger}
          initial="initial"
          animate="stagger"
          transition={{ duration: 0.2, ...transition }}
        >
          <Link
            to="/project/switch"
            className="project"
            onClick={() => {
              showPanels(true);
            }}
            onMouseEnter={() => setCursorHovered(true)}
            onMouseLeave={() => setCursorHovered(false)}
            ref={list}
          >
            <motion.h1 ref={hoverProjectOne}>
              {projectOne ? (
                <motion.span
                  initial={{
                    y: y - listPosition.top + 60,
                    x: x - listPosition.left - 250,
                    opacity: 0.2,
                  }}
                  animate={{
                    y: y - listPosition.top + 60,
                    x: x - listPosition.left - 250,
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.2,
                    ease: [0.6, 0.01, -0.05, 0.9],
                  }}
                  className="project-video"
                >
                  <video height="400" autoPlay muted loop>
                    <source src={Switch} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </motion.span>
              ) : null}
              Switch
            </motion.h1>
            <div className="line one"></div>
          </Link>
        </motion.div>

        <motion.div
          custom={0.2}
          variants={stagger}
          initial="initial"
          animate="stagger"
          transition={{ duration: 0.2, ...transition }}
        >
          <Link
            to="/project/playpaw"
            className="project"
            onClick={() => {
              showPanels(true);
            }}
            onMouseEnter={() => setCursorHovered(true)}
            onMouseLeave={() => setCursorHovered(false)}
            ref={list}
          >
            <motion.h1 ref={hoverProjectTwo}>
              {projectTwo ? (
                <motion.span
                  initial={{
                    y: y - listPosition.top - 50,
                    x: x - listPosition.left - 270,
                    opacity: 0.2,
                  }}
                  animate={{
                    y: y - listPosition.top - 50,
                    x: x - listPosition.left - 270,
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.2,
                    ease: [0.6, 0.01, -0.05, 0.9],
                  }}
                  className="project-video"
                >
                  <video height="400" autoPlay muted loop>
                    <source src={PlayPaw} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>{" "}
                </motion.span>
              ) : null}
              PlayPaw
            </motion.h1>
            <div className="line two"></div>
          </Link>
        </motion.div>
        <motion.div
          custom={0.4}
          variants={stagger}
          initial="initial"
          animate="stagger"
          transition={{ duration: 0.2, ...transition }}
        >
          <Link
            to="/project/medmen"
            className="project"
            onClick={() => {
              showPanels(true);
            }}
            onMouseEnter={() => setCursorHovered(true)}
            onMouseLeave={() => setCursorHovered(false)}
            ref={list}
          >
            <motion.h1 ref={hoverProjectThree}>
              {projectThree ? (
                <motion.span
                  initial={{
                    y: y - listPosition.top - 120,
                    x: x - listPosition.left - 330,
                    opacity: 0.2,
                  }}
                  animate={{
                    y: y - listPosition.top - 120,
                    x: x - listPosition.left - 330,
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.2,
                    ease: [0.6, 0.01, -0.05, 0.9],
                  }}
                  className="project-video"
                >
                  <video height="200" autoPlay muted loop>
                    <source src={MedMen} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </motion.span>
              ) : null}{" "}
              MedMen
            </motion.h1>
            <div className="line three"></div>
          </Link>
        </motion.div>
      </motion.div>

      <div className="project-titles-mobile">
        <div className="project">
          <div className="line one"></div>
          <Link to="/project/switch">
            <motion.h1>Switch</motion.h1>
            <Arrow />
          </Link>
        </div>
        <div className="project">
          <div className="line two"></div>
          <Link to="/project/playpaw">
            <motion.h1>PlayPaw</motion.h1>
            <Arrow />
          </Link>
        </div>
        <div className="project">
          <div className="line three"></div>
          <Link to="/project/medmen">
            <motion.h1>MedMen</motion.h1>
            <Arrow />
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

const Arrow = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 14.6875H23.0581L12.9262 4.55562L15.5 2L30 16.5L15.5 31L12.9444 28.4444L23.0581 18.3125H1V14.6875Z"
        fill="black"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default Work;
